.how-to-play
{
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    padding: 1em;

    h3
    {
        margin-bottom: 0em;
    }

    .section
    {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 0.5em;
        align-items: center;

        .iconify
        {
            flex-shrink: 0;
        }

        p
        {
            flex-grow: 1;
        }
    }

    .buttons
    {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
    }

    .button
    {
        width: auto;
        padding: 0.5em 1em;
        border: none;
        border-radius: 4px;
        background-color: #198a36;
        color: white;
        font-size: 1em;
        cursor: pointer;
        transition: all 0.1s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5em;

        &:hover
        {
            background-color: #00b30f;
        }

        &.discord
        {
            background-color: #7289da;
            color: white;

            &:hover
            {
                background-color: #677bc4;
            }
        }
    }
}