@import '../shared.scss';

.replay-entry
{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5em;

    .button
    {
        width: 8em;
        background-color: rgb(57, 107, 214);
        flex-shrink: 0;
        border-radius: 0.5em;
        padding: 0.2em 0.5em;
        color: white;
        font-size: 1em;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;

        &:hover
        {
            background-color: rgb(47, 97, 204);
        }

        &:active
        {
            background-color: rgb(37, 87, 194);
        }
    }

    .emojis
    {
        flex-shrink: 0;
    }

    img
    {
        width: 2em;
        height: 2em;
    }

    .answer
    {
        width: 15em;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        flex-grow: 1;

        .wrapper
        {

            .space
            {
                display: none;
            }

            .game-name:nth-child(3)
            {
                display: none;
            }
        }

        &.correct
        {
            background-color: $color;
            color: transparent;
            cursor: pointer;
            transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

            .wrapper
            {
                display: inline-block;
                animation: scroll-left-to-right 5s linear infinite;
                
                .space
                {
                    display: inline-block;
                    width: 2em;
                }

                .game-name
                {
                    display: inline-block;
                    pointer-events: none;
                    user-select: none;
                }
            }

            &.admin
            {
                background-color: transparent;
                color: $color;
            }

            &:active
            {
                background-color: transparent;
                color: $color;
            }
        }
    }

    @include mobile
    {
        flex-wrap: wrap;

        .answer
        {
            &.correct
            {
                &:hover
                {
                    background-color: $color;
                    color: transparent;
                }
            }
        }
    }

}

@keyframes scroll-left-to-right
{
    0%
    {
        transform: translateX(0%);
    }

    100%
    {
        transform: translateX(-50%);
    }
}