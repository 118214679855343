@import 'shared.scss';

.root-panel
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 600px;
    max-width: 600px;
    flex-grow: 1;
    padding: 16px;
    gap: 1em;

    > .content
    {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 1em;

        
        @include mobile
        {
            width: 80%;
        }
    }

    a
    {
        color: rgb(222, 224, 122);
        text-decoration: none;
        font-weight: 600;
        transition: color 0.1s ease;

        &:hover
        {
            color: darken($color, 10);
        }
    }

    @include mobile
    {
        width: 100%;
        max-width: 100vw;
        padding: 0;
    }
}