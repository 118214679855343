@import "../shared.scss";

.results-panel
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    p
    {
        margin-top: 0px;
        margin-bottom: 0.4em;
    }

    .result-title
    {
        font-size: 1.5em;
        font-weight: 700;
    }

    .result-video
    {
        aspect-ratio: 1.7777777778;
        margin-top: 1em;
    }

    .options
    {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-evenly;
        margin-top: 1em;
        margin-bottom: 1em;
        align-items: center;

        .button
        {
            width: 12em;
            padding: 0.5em 1em;
            border: none;
            border-radius: 4px;
            background-color: #007bff;
            color: white;
            font-size: 1em;
            cursor: pointer;
            transition: all 0.1s ease;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.5em;

            &:hover
            {
                background-color: #0056b3;
            }

            @include mobile
            {
                font-size: 0.8em;
                width: auto;
            }
        }

        

        @include mobile
        {
            gap: 1em;
        }
    }

    .time-until-next
        {
            font-size: 1.2em;
            font-weight: 700;

            .title
            {
                font-size: 0.8em;
                font-weight: 400;
            }
        }

    @include mobile
    {
        width: 100%;
    }
}