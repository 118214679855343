@import "../../shared.scss";

.stats-panel
{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;

    h3
    {
        margin-bottom: 0px;
    }

    .chart
    {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .chart-entry
        {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .chart-number
            {
                width: 30px;
            }

            .chart-bar
            {
                position: relative;
                flex-grow: 1;
                background-color: grey;
                height: 16px;
                border-radius: 4px;

                .chart-fill
                {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    border-radius: 4px;
                    background-color: rgb(0, 140, 255);
                    min-width: 40px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 0.7em;

                    &.loss
                    {
                        background-color: rgb(255, 0, 0);
                    }
                }
            }
        }
    }

    .info
    {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        background-color: lighten($background-color, 6);
        padding: 8px 16px;
        border-radius: 8px;
        box-shadow: 0 0 8px rgba(black, 0.3);

        .info-entry
        {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 150px;

            .info-title
            {
                font-size: 0.9em;
                font-weight: 400;
            }

            .info-value
            {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                font-size: 1em;
                font-weight: 600;

                &.smaller
                {
                    font-size: 0.8em;
                }

                &.bigger
                {
                    font-size: 1.5em;
                }
            }
        }
    }
}