@import 'shared.scss';

.results
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    width: 100%;

    p
    {
        margin: 0;
    }
}

.info-panel
{
    font-size: 70%;
    color: rgba(white, 0.5);

    .ended-link
    {
        text-decoration: underline;
        cursor: pointer;

        &:hover
        {
            color: white;
        }

        &:active
        {
            color: rgba(white, 0.8);
        }
    }
}