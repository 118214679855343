@import "../shared.scss";

.guess-bar
{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;

    .guess-box
    {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        input
        {
            width: 100%;
            padding: 10px 6px;
            font-size: 1em;
            border: 1px solid #ccc;
            border-radius: 5px;
            align-self: center;
        }

        .guess-results
        {
            position: absolute;
            color: rgba(rgb(0, 0, 0), 0.8);
            bottom: 4em;
            width: 100%;
            max-height: 300px;
            background-color: white;
            overflow-y: scroll;
            backdrop-filter: blur(4px);
            border-radius: 16px;
            display: flex;
            flex-direction: column-reverse;
            

            .guess-result
            {
                padding: 0.5em;
                border-radius: 8px;
                transition: all 0.1s ease;
                cursor: pointer;
                pointer-events: all;

                *
                {
                    pointer-events: none;
                }

                &.active
                {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                &:hover
                {
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }

            .highlight
            {
                color: rgb(0, 0, 0);
                font-weight: 700;
            }
        }
    }

    .guess-controls
    {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: calc(100% + 12px);

        .button
        {
            padding: 8px 20px;
            font-size: 1em;
            background-color: grey;
            border-radius: 4px;
            cursor: pointer;
            transition: all 0.1s ease;

            &:hover
            {
                background-color: #f0f0f0;
                color: $background-color;
            }

            &:active
            {
                transform: scale(0.95);
            }

            &.skip
            {
                background-color: #e24646;
                color: white;

                &:hover
                {
                    background-color: #ce6d6d;
                    color: white;
                }
            }

            &.guess
            {
                background-color: #4b9e4b;
                color: white;

                &:hover
                {
                    background-color: #6dbd6d;
                    color: white;
                }
            }
        }
    }

    @include mobile
    {
        width: 100%;
    }
}