@import '../shared.scss';

.hint-panel
{
    width: 100%;
    background-color: lighten($background-color, 6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(black, 0.3);

    .hint-entry
    {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 150px;

        .hint-title
        {
            font-size: 0.9em;
            font-weight: 400;
        }

        .hint-value
        {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 1em;
            font-weight: 600;

            .console
            {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                position: relative;
                gap: 0.25em;

                .console-name
                {
                    margin-left: -16px;
                    max-width: 120px;
                    text-align: right;
                }

                .console-image
                {
                    position: absolute;
                    left: calc(100% + 8px);
                    bottom: 0;
                    height: 32px;
                }
            }
        }
    }

    @include mobile
    {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5em 4em;

        .hint-entry
        {
            font-size: 0.8em;
        }
    }
}