@import "../../shared.scss";

.popup
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color, 0.1);
    backdrop-filter: blur(2px);

    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 100;
    opacity: 1;

    transition: all 0.3s ease;
    animation: popup-fade-in 0.3s ease;

    padding-top: 4em;

    > .popup-content
    {
        background-color: $background-color;
        padding: 16px 32px;
        border-radius: 8px;
        box-shadow: 0 0 8px rgba(black, 0.3);
        max-width: 450px;
        transition: all 0.3s ease;
        transform-origin: center;
        transform: scale(1);

        > .close-button
        {
            position: absolute;
            top: 8px;
            right: 8px;
            cursor: pointer;
            transform-origin: center;
            transform: scale(1);
            transition: transform 0.1s ease;

            &:hover
            {
                transform: scale(1.1);
            }

            &:active
            {
                transform: scale(0.9);
            }
        }
    }

    &.closed
    {
        opacity: 0;
        pointer-events: none;

        > .popup-content
        {
            transform: scale(0);
        }
    }

    @include mobile
    {
        font-size: 0.8em;
        
        > .popup-content
        {
            max-width: 70vw;
        }
    }
}

.its-over
{
    > .br
    {
        margin: 16px;
    }
}