@import "../shared.scss";

.app-header
{
    display: flex;
    flex-direction: column;
    width: 100%;

    header
    {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        width: 100%;
        justify-content: space-between;

        a
        {
            color: $color;
        }

        .iconify
        {
            cursor: pointer;
            transform-origin: center;
            transform: scale(1);
            transition: transform 0.1s ease;

            &:hover
            {
                transform: scale(1.1);
            }

            &:active
            {
                transform: scale(0.9);
            }
        }

        .options
        {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 4px;
        }

        .logo
        {
            font-size: 1.2em;
            text-transform: uppercase;
            font-weight: 600;
            text-align: center;
        }
    }

    .line
    {
        height: 8px;
        width: 100%;
        border-bottom: 1px solid #cccccc67;
    }

    @include mobile
    {
        padding-top: 1em;
        width: 90%;
    }
}