
$background-color: #282c34;
$color: white;
$font-size: 1.5em;
$font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

:root {
  --toastify-color-progress-dark: #dadada;
}

// Mobile breakpoints
@mixin mobile {
    @media screen and (max-width: 640px) {
      @content;
    }
  }