@import "../shared.scss";

.track-player
{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75em;

    .track
    {
        background-color: lighten($background-color, 8);
        padding: 6px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        transition: all 0.5s ease;
        width: 100%;

        @include mobile
        {
            width: 120%;
        }

        .track-title
        {
            position: relative;
            bottom: 1px;
            font-size: 1.2em;
        }

        .end-content
        {
            display: flex;
            flex-direction: row;
            gap: 0.5em;

            .icons
            {
                display: flex;
                flex-direction: row;
                gap: 0.5em;

                .icon
                {
                    position: relative;
                    bottom: 2px;
                    font-size: 1.2em;
                    user-select: none;
                    cursor: pointer;
                    text-shadow: 0px 0px 1px $color;
                }
            }

            .play-button
            {
                position: relative;
                display: flex;
                border: 1px solid $color;
                border-radius: 12px;
                width: 42px;
                height: 24px;
                justify-content: center;
                align-items: center;
                transition: all 0.1s ease;
                top: 0px;

                &:hover
                {
                    background-color: $color;
                    color: $background-color;
                }

                &:active
                {
                    top: 1px;
                }
            }
        }

        &.current
        {
            transform: scale(1.05);
            background-color: lighten($background-color, 24);
            
            .track-title
            {
                font-weight: 600;
                color: rgb(81, 211, 81);
            }
        }

        &.missed
        {
            .track-title
            {
                color: rgb(212, 87, 87);
            }
        }
    }

    > .play-button
    {
        position: relative;
        display: flex;
        border: 1px solid $color;
        border-radius: 24px;
        width: 92px;
        height: 42px;
        justify-content: center;
        align-items: center;
        align-self: center;
        transition: all 0.1s ease;
        margin: 0.25em;

        .play-button-tooltip
        {
            position: absolute;
            top: calc(100% + 1.2em);
            width: 260px;
            padding: 0.5em;
            background-color: black;
            pointer-events: none;
            color: white;
            animation: bob-up-down 2s infinite ease-in-out;

            &::before
            {
                content: "";
                position: absolute;
                top: -1em;
                left: 50%;
                transform: translateX(-50%);
                border: 0.5em solid transparent;
                border-bottom-color: black;
            }
        }

        &:hover
        {
            background-color: $color;
            color: $background-color;
        }

        &:active
        {
            top: 1px;
        }
    }
}

@keyframes bob-up-down
{
    0%
    {
        transform: translateY(0);
    }

    50%
    {
        transform: translateY(-0.25em);
    }

    100%
    {
        transform: translateY(0);
    }
}